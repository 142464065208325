import { gql } from '@apollo/client';

export const getAllProfiles = gql`
  query {
    allProfiles {
      slug
      profileType
      status
      lastRun
      cronExpression
      profileName
      exportMethod
      fileExtension
      columnSeparator
      ftpHost
      ftpUsername
      decryptedFtpPassword
      ftpFilePath
      googleSheetUrl
      googleSheetName
      recipientEmail
      emailSubject
      emailBody
      fileName
      allFields
      scheduleEnabled
      scheduleType
      scheduleFrequency
      scheduleRunTime
      hourlyFrequency
      scheduleCustomDays
      exportOnNewPage
      templateName
      connectionParams
      customFilters {
        excludeHiddenProducts
        excludeEmptyImages
        includeProductCategories
        excludeProductCategories
        includeProductBrands
        excludeProductBrands
        includeQuantity
        includeSkus
        excludeSkus
      }
      exportFields {
        fieldName
        identifier
        position
        staticValue
      }
      latestLog {
        success
        remark
        startedBy
        totalExportedData
      }
      webhook {
        scope
      }
      productCustomFields
      googleClearSheet
      googleAppendNextRow
      emailSender
    }
  }
`;

export const createNewProfile = gql`
  mutation ($attributes: ProfileInput!) {
    createProfile(attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        customFilters {
          excludeHiddenProducts
          excludeEmptyImages
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeQuantity
          includeSkus
          excludeSkus
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const deleteProfile = gql`
  mutation ($slug: String!) {
    deleteProfile(slug: $slug) {
      success
      message
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        customFilters {
          excludeHiddenProducts
          excludeEmptyImages
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeQuantity
          includeSkus
          excludeSkus
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const editProfile = gql`
  mutation ($slug: String!, $attributes: ProfileInput!) {
    editProfile(slug: $slug, attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        exportOnNewPage
        templateName
        connectionParams
        allFields
        customFilters {
          excludeHiddenProducts
          excludeEmptyImages
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeQuantity
          includeSkus
          excludeSkus
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const executeProfile = gql`
  mutation ($slug: String!) {
    executeProfile(slug: $slug) {
      success
      message
    }
  }
`;

export const cancelProcess = gql`
  mutation ($slug: String!) {
    cancelProcess(slug: $slug) {
      success
      message
    }
  }
`;

export const updateProfileName = gql`
  mutation ($slug: String!, $newName: String!) {
    updateProfileName(slug: $slug, newName: $newName) {
      success
      message
      profile {
        slug
        profileName
      }
    }
  }
`;

export const updateProductFilters = gql`
  mutation ($slug: String!, $filters: ProductFilterInput!) {
    updateProductFilter(slug: $slug, filters: $filters) {
      success
      message
      profile {
        slug
      }
    }
  }
`;

export const createNewTemplate = gql`
  mutation ($attributes: ProductTemplateInput!) {
    createProductTemplate(attributes: $attributes) {
      message
      success
      profile {
        slug
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        customFilters {
          excludeHiddenProducts
          excludeEmptyImages
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeQuantity
          includeSkus
          excludeSkus
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const updatePosition = gql`
  mutation ($slug: String!, $input: [JSON!]!) {
    updateFieldPosition(slug: $slug, input: $input) {
      profile {
        slug
      }
    }
  }
`;

export const duplicateProfile = gql`
  mutation ($slug: String!) {
    duplicateProductProfile(slug: $slug) {
      message
      success
      profile {
        slug
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        customFilters {
          excludeHiddenProducts
          excludeEmptyImages
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeQuantity
          includeSkus
          excludeSkus
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const editSchedule = gql`
  mutation ($slug: String!, $schedule: ScheduleInput!) {
    setSchedule(slug: $slug, schedule: $schedule) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        customFilters {
          excludeHiddenProducts
          excludeEmptyImages
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeQuantity
          includeSkus
          excludeSkus
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;
