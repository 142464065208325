import React from 'react';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';

import { getDifferenceInDays } from '@/config/function';
import { useStore } from '@/hooks/useStore';

const bannerMessages = {
  expiring: {
    severity: 'warning',
    title: "Hello, you're currently on the Free Trial!",
    renderSubtitle: (daysLeft) => (
      <>
        Your Free Trial will be ending in <strong>{daysLeft} day(s)</strong>.
        Kindly subscribe to a package to avoid any service interruption.
      </>
    ),
  },
  expired: {
    severity: 'error',
    title: 'Hey, your Free Trial has ended!',
    renderSubtitle: (daysLeft) => (
      <>
        Please subscribe to our package to continue your journey or{' '}
        <Link
          onClick={() => {
            window.FreshworksWidget == undefined
              ? window.fcWidget.open()
              : FreshworksWidget('open');
          }}
          underline="always"
          style={{ cursor: 'pointer' }}
        >
          contact our team
        </Link>{' '}
        for further enquiries.
      </>
    ),
  },
};

const BillingBanner = ({ onTrial = false, daysLeft }) => {
  const key = onTrial ? 'expiring' : 'expired';
  const obj = bannerMessages[key];

  return (
    <Alert
      variant="outlined"
      severity={obj.severity}
      style={{ marginBottom: 10 }}
    >
      <AlertTitle>{obj.title}</AlertTitle>
      {obj.renderSubtitle(daysLeft)}
    </Alert>
  );
};

const ExpiredStore = () => {
  const { hasValidPlan, onTrialPlan, trialExpiryDate } = useStore();
  const daysLeft = getDifferenceInDays(trialExpiryDate);

  const elem = hasValidPlan ? (
    onTrialPlan && <BillingBanner onTrial daysLeft={daysLeft} />
  ) : (
    <BillingBanner />
  );

  return elem;
};

export default ExpiredStore;
