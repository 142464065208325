import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';

import { loginStyle } from '@/config/style';

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://exportstash.com/">
      Export Stash
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

const SUPPORTED_PLATFORMS = [
  {
    name: 'BigCommerce',
    url: 'https://login.bigcommerce.com/app/kntflzcdnihpr4ih8srrl3udkzyv2tu/install',
  },
  {
    name: 'Wix',
    url: '',
  },
  {
    name: 'Ecwid',
    url: 'https://www.ecwid.com/apps/featured/export-stash-app',
  },
];

const APP_INSTALL_URL =
  'https://login.bigcommerce.com/app/kntflzcdnihpr4ih8srrl3udkzyv2tu/install';

export const LoginPage = () => {
  const classes = loginStyle();
  return (
    <>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Export Stash
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              Exporting your store orders and products as simple and as quickly
              as possible.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                {SUPPORTED_PLATFORMS.map((platform) => (
                  <Grid item>
                    <Button
                      href={platform.url}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {`Find us on ${platform.name}`}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Container>
        </div>

        <Container className={classes.cardGrid} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia component={card.component} />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Typography>{card.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Link href={'/privacypolicy'}>
          <Typography
            variant="subtitle2"
            align="center"
            color="textSecondary"
            component="p"
          >
            Privacy Policy
          </Typography>
        </Link>

        <Link href={'/terms'}>
          <Typography
            variant="subtitle2"
            align="center"
            color="textSecondary"
            component="p"
          >
            Terms of Use
          </Typography>
        </Link>
        <Copyright />
      </footer>
      {/* End footer */}
    </>
  );
};

const StepOne = () => (
  <svg
    id="a43d4114-cd98-41f6-b9a0-b0700c71b140"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="300"
    height="200"
    viewBox="0 0 300 628.5076"
  >
    <ellipse cx="325" cy="618.5076" rx="199" ry="10" fill="#e6e6e6" />
    <circle cx="98.7538" cy="98.7538" r="98.7538" fill="#e6e6e6" />
    <circle cx="98.7538" cy="98.7538" r="82.29483" fill="#3d00ff" />
    <circle cx="361" cy="98.7538" r="98.7538" fill="#e6e6e6" />
    <circle cx="361" cy="98.7538" r="82.29483" fill="#fff" />
    <circle cx="623.2462" cy="98.7538" r="98.7538" fill="#e6e6e6" />
    <circle cx="623.2462" cy="98.7538" r="82.29483" fill="#fff" />
    <polygon
      points="91.121 132.566 67.146 101.741 81.088 90.896 92.44 105.492 130.789 65.011 143.612 77.16 91.121 132.566"
      fill="#fff"
    />
    <path
      d="M512.74375,254.63706c-6.48534-15.6637-7.53825-30.44721,1.60416-43.5788h0v0a32.97462,32.97462,0,0,1,35.17524-31.68663l5.96094.40277a32.84642,32.84642,0,0,1,28.29082,20.38647c11.11549,27.56779,11.46537,64.20685,8.6913,103.47825H510.94C515.78212,285.27325,518.268,268.03621,512.74375,254.63706Z"
      transform="translate(-239 -135.7462)"
      fill="#2f2e41"
    />
    <path
      d="M407.46181,302.69572l-21.54424-9.67784a16.4179,16.4179,0,0,1-7.14015-23.76472l0,0a16.4179,16.4179,0,0,1,26.59776-1.57931l9.9532,12.22112,46.40234,41.15415,30.84512-20.584,13.9796,23.70151-50.03519,30.22389Z"
      transform="translate(-239 -135.7462)"
      fill="#9f616a"
    />
    <path
      d="M504.30871,331.13847l-17.00916-32.54663,66.23159-29.50091.57344,6.22557a33.008,33.008,0,0,1-13.61467,29.83813Z"
      transform="translate(-239 -135.7462)"
      fill="#3d00ff"
    />
    <path
      d="M590.13685,267.14641l-67.55026,55.90367-3.8822-5.43508,18.63455-33.38691,8.54084-3.8822c2.04631-8.66972-3.1777-20.29608-10.09372-32.61047l32.61047-4.65864C569.97055,253.6534,577.1384,261.71207,590.13685,267.14641Z"
      transform="translate(-239 -135.7462)"
      fill="#9f616a"
    />
    <polygon
      points="432.663 517.291 421.405 533.984 331.726 483.127 344.149 421.789 432.663 517.291"
      fill="#2f2e41"
    />
    <path
      d="M553.256,732.62207l-18.24634-8.15262L530.351,510.94851,512.88109,394.87076l67.93848-12.81126,13.97592,30.28116C629.14072,459.44606,579.07306,603.77941,553.256,732.62207Z"
      transform="translate(-239 -135.7462)"
      fill="#2f2e41"
    />
    <path
      d="M554.727,729.72092l3.74388,8.14845a10.82449,10.82449,0,0,1-8.30515,15.23492l-49.49793,7.07113a10.1726,10.1726,0,0,1-11.21982-7.27573h0a10.17259,10.17259,0,0,1,4.6703-11.59011l41.58323-20.81312Z"
      transform="translate(-239 -135.7462)"
      fill="#2f2e41"
    />
    <path
      d="M669.34554,649.88259l8.94332-.65645a10.82449,10.82449,0,0,1,11.36645,13.11038l-10.69313,48.84366a10.1726,10.1726,0,0,1-10.74129,7.96525h0a10.17261,10.17261,0,0,1-9.22368-8.43008l-4.94943-46.23693Z"
      transform="translate(-239 -135.7462)"
      fill="#2f2e41"
    />
    <circle cx="308.43266" cy="91.80178" r="31.05759" fill="#9f616a" />
    <path
      d="M579.26669,386.71814l-67.55026,13.97592-1.55288-45.03351c-9.78142-10.35567,13.03153-51.83053,20.96387-71.43246l10.09372-3.10576-17.08167,31.05759,59.78586-48.91571,11.6466,3.10576,3.10576,56.68011Z"
      transform="translate(-239 -135.7462)"
      fill="#3d00ff"
    />
    <path
      d="M583.92533,413.89353l-14.64879,18.52641a16.4179,16.4179,0,0,1-24.789,1.11683l0,0a16.4179,16.4179,0,0,1,4.9677-26.17741l14.28273-6.66527,51.245-34.93979-12.423-34.93979,26.399-7.7644,17.08167,55.90366Z"
      transform="translate(-239 -135.7462)"
      fill="#9f616a"
    />
    <path
      d="M586.642,297.42757h-56.2686c2.11426-10.63575,7.78226-20.28466,23.28356-27.20291,11.303-21.60269,16.13191-41.19716,0-54.28965-18.42666-4.245-25.56315-11.95345-23.28356-22.55038l32.985-5.43508C590.48494,212.10816,590.79463,253.34681,586.642,297.42757Z"
      transform="translate(-239 -135.7462)"
      fill="#2f2e41"
    />
    <path
      d="M635.17036,326.93228l-35.71623,8.54084-12.423-71.43247,6.177.96516A33.00806,33.00806,0,0,1,618.815,285.4987Z"
      transform="translate(-239 -135.7462)"
      fill="#3d00ff"
    />
  </svg>
);

const StepTwo = () => (
  <svg
    id="af9d2f0a-1bcf-46ad-845e-9c37ef0f1153"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="300"
    height="200"
    viewBox="0 0 300 512.62854"
  >
    <g id="a4cc79cd-ea29-44e8-8b1f-c84c9f36b99b" data-name="Group 29">
      <circle
        id="b528ca4c-b6e8-4382-8c63-b3f7a5882e55"
        data-name="Ellipse 108"
        cx="103.19117"
        cy="179.22455"
        r="30"
        fill="#2f2e41"
      />
      <circle
        id="f2334603-0630-41b1-8883-db91ebd0df1d"
        data-name="Ellipse 109"
        cx="105.71115"
        cy="184.72055"
        r="24.561"
        fill="#a0616a"
      />
      <path
        id="a98f854d-ddd1-49f9-826e-9b3b51c42b30"
        data-name="Path 704"
        d="M580.47357,453.11426a10.51855,10.51855,0,0,0-1.072,1.264l-49.562-1.751-5.669-10.631-17.162,6.458,8.206,19.8a8,8,0,0,0,8.263,4.889l56.085-6.158a10.50026,10.50026,0,1,0,.911-13.866Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#a0616a"
      />
      <path
        id="f257447d-301d-44a2-8e09-7759e64535dc"
        data-name="Path 705"
        d="M500.40658,458.01428a4.81194,4.81194,0,0,1-2.643-3.017l-6.9-23.169a13.37727,13.37727,0,1,1,24.208-11.392h0l13.372,20.235a4.8171,4.8171,0,0,1-1.981,7.02l-22.04105,10.289a4.811,4.811,0,0,1-4.011.03Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="becfa795-45e6-4313-bdd8-8732b5eb4129"
        data-name="Path 706"
        d="M549.22159,693.24127l10.768-5.862-17.485-44.322-15.892,8.651Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#a0616a"
      />
      <path
        id="e8c8979c-fc56-471c-a205-c14c5d72c2f7"
        data-name="Path 707"
        d="M544.56158,691.22028l21.205-11.544h0a15.386,15.386,0,0,1,20.86969,6.15747l.0003.00055.239.439-34.72,18.9Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#2f2e41"
      />
      <path
        id="ab11d9ef-02df-4748-a171-c660a3aec0b0"
        data-name="Path 708"
        d="M430.73458,693.28925h12.259l5.833-47.288h-18.1Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#a0616a"
      />
      <path
        id="af78ce6e-a300-42ae-85b6-279af7ebed37"
        data-name="Path 709"
        d="M427.60656,689.28729h24.145a15.386,15.386,0,0,1,15.386,15.386v.501h-39.53Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#2f2e41"
      />
      <path
        id="b8635a3a-781b-4583-ae1f-43c79305ccfd"
        data-name="Path 710"
        d="M506.91558,483.41425l4,48,44.619,133.856a4,4,0,0,1-2.52991,5.05957q-.16684.05566-.3381.09644l-13.675,3.256a4,4,0,0,1-4.5-2.091l-57.043-113.157a2,2,0,0,0-3.739.471l-25.1,114.362a4,4,0,0,1-3.907,3.142h-12.726a3.993,3.993,0,0,1-4.00046-3.98553q0-.03424.00046-.06848c1.166-79.3-2.68-169.653,27.936-183.946l5-13Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#3f3d56"
      />
      <path
        id="e573b899-9021-4f9b-8786-b9ea4c0ab316"
        data-name="Path 711"
        d="M459.47558,478.81427l-5.154-31.177a30.877,30.877,0,0,1,9.769-28.006,30.36108,30.36108,0,0,1,28.331-6.925c.292.078.585.159.878.241a30.783,30.783,0,0,1,22.222,27.346c.984,13.54.1,33.184-9.678,49.87l-.19.324Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="bf1af282-84f0-4284-9d03-260240daa088"
        data-name="Path 712"
        d="M511.36859,365.35226a33.39994,33.39994,0,0,1-19.091,5.9,20.47,20.47,0,0,0,8.114,3.338,67.35852,67.35852,0,0,1-27.514.155,17.807,17.807,0,0,1-5.76-1.978,7.2889,7.2889,0,0,1-3.555-4.755c-.6-3.449,2.083-6.582,4.876-8.693a35.967,35.967,0,0,1,30.224-6.04c3.376.873,6.758,2.347,8.951,5.059s2.843,6.892.753,9.684Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#2f2e41"
      />
      <path
        id="bd051e82-93fb-484c-bc2c-a05379150ad3"
        data-name="Path 713"
        d="M484.02157,342.76227c2.847-11.5-7.357-24.268-19.2-24.029-12.013.243-20.854,11.1-26.931,21.464s-12.115,22.117-23.269,26.58a24.029,24.029,0,0,1-32.946-22.894c-1.5,12.593.577,26.219,8.7,35.96s23.233,14.13,34.1,7.584c6.2-3.739,10.169-10.237,13.438-16.7s6.178-13.279,11.131-18.564,12.612-8.792,19.506-6.569c2.736.883,5.191,2.6,8.018,3.126s6.386-.82,6.772-3.669Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#2f2e41"
      />
      <path
        id="a038711e-9241-43ca-acb8-65ac7fef7c62"
        data-name="Path 714"
        d="M424.82159,551.20526a10.743,10.743,0,0,1,2.743-16.243l21.305-95.5,21.613,8.871-27.737,91.081a10.8,10.8,0,0,1-17.924,11.791Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#a0616a"
      />
      <path
        id="a8e2c108-97db-4287-b949-04189b24fe3b"
        data-name="Path 715"
        d="M440.43057,453.16425a4.812,4.812,0,0,1-.336-4l8.193-22.745a13.37759,13.37759,0,0,1,26.245,5.2h0l-1.245,24.222a4.81686,4.81686,0,0,1-5.76,4.475l-23.85-4.8a4.812,4.812,0,0,1-3.246-2.357Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
    </g>
    <g id="a8ec7779-81bc-4fb5-bea3-5b8fa1590d68" data-name="Group 28">
      <path
        id="a61284fa-5c43-4b2c-a220-e074c1426ed7"
        data-name="Path 410"
        d="M681.50659,197.34473a5.982,5.982,0,0,0-5.468-3.659H566.8776a5.92,5.92,0,0,0-5.917,5.917v138.095a5.92,5.92,0,0,0,5.917,5.917h109.161a5.925,5.925,0,0,0,5.917-5.917v-138.093A5.80986,5.80986,0,0,0,681.50659,197.34473Zm-.865,140.352a4.609,4.609,0,0,1-4.6,4.6H566.8776a4.6,4.6,0,0,1-4.6-4.6h0v-138.092a4.609,4.609,0,0,1,4.6-4.6h109.161a4.62606,4.62606,0,0,1,4.273,2.9,5.11465,5.11465,0,0,1,.173.519,4.55869,4.55869,0,0,1,.158,1.188Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="f058fc04-be1a-4760-b4ca-1d7d0096a0b4"
        data-name="Path 411"
        d="M658.7236,226.29175h-32.88a2.6305,2.6305,0,0,1,0-5.261h32.879a2.631,2.631,0,0,1,0,5.261Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="b7b03d80-c54f-4ac7-a733-77e3791388e5"
        data-name="Path 412"
        d="M658.7236,240.10175h-32.88a2.6305,2.6305,0,0,1,0-5.261h32.879a2.631,2.631,0,0,1,0,5.261Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="bbd645bb-3528-4838-b3c9-db478370a388"
        data-name="Path 413"
        d="M608.97759,247.96475h-24.033a2.962,2.962,0,0,1-2.959-2.959v-28.355a2.962,2.962,0,0,1,2.959-2.959h24.033a2.963,2.963,0,0,1,2.959,2.959v28.355A2.962,2.962,0,0,1,608.97759,247.96475Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="a70c21c1-6213-4b7f-bc14-591f551ac782"
        data-name="Path 414"
        d="M658.4946,268.37775h-74.307a2.6305,2.6305,0,0,1,0-5.261h74.307a2.63049,2.63049,0,0,1,0,5.261Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="af88612c-f069-4016-a622-4620c9d6e9ee"
        data-name="Path 415"
        d="M658.4946,282.19174h-74.307a2.63049,2.63049,0,0,1,0-5.261h74.307a2.63049,2.63049,0,1,1,0,5.261Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="b4028583-530b-4323-a807-42e8359c25be"
        data-name="Path 416"
        d="M658.4946,295.99673h-74.307a2.63049,2.63049,0,0,1,0-5.261h74.307a2.63049,2.63049,0,0,1,0,5.261Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="eea2a91b-a87e-450d-ac84-b33fc6ad943a"
        data-name="Path 417"
        d="M658.4946,309.80975h-74.307a2.63049,2.63049,0,0,1,0-5.261h74.307a2.63049,2.63049,0,0,1,0,5.261Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
      <path
        id="f69f5fd9-c3c3-49bc-9ead-71d5a754157a"
        data-name="Path 418"
        d="M658.4946,323.61575h-74.307a2.63049,2.63049,0,0,1,0-5.261h74.307a2.63049,2.63049,0,0,1,0,5.261Z"
        transform="translate(-381.22442 -193.68573)"
        fill="#ccc"
      />
    </g>
    <path
      id="b052e332-5d61-4357-af09-a12de450e4ae"
      data-name="Path 336"
      d="M747.43456,278.88326a7.6,7.6,0,0,0-6.95-4.65h-138.725a7.524,7.524,0,0,0-7.52,7.52v175.5a7.524,7.524,0,0,0,7.52,7.52h138.73a7.53,7.53,0,0,0,7.52-7.52v-175.5a7.38544,7.38544,0,0,0-.57-2.87Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#e6e6e6"
    />
    <path
      d="M736.13867,454.91992H606.49414c-1.86816-.00195-3.33105-2.0459-3.33105-4.65283V287.46729c.00195-2.60938,1.46582-4.65284,3.33105-4.65284H736.13867c1.86914.00244,3.332,2.0459,3.332,4.65284v162.7998C739.46875,452.876,738.00488,454.91992,736.13867,454.91992Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#fff"
    />
    <path
      id="a0e2f6aa-04b8-4ab3-a7b0-0f19b8b6a28a"
      data-name="Path 336"
      d="M747.43456,278.88326a7.6,7.6,0,0,0-6.95-4.65h-138.725a7.524,7.524,0,0,0-7.52,7.52v175.5a7.524,7.524,0,0,0,7.52,7.52h138.73a7.53,7.53,0,0,0,7.52-7.52v-175.5a7.38544,7.38544,0,0,0-.57-2.87Zm-1.1,178.37a5.857,5.857,0,0,1-5.85,5.85h-138.725a5.851,5.851,0,0,1-5.85-5.848V281.75426a5.858,5.858,0,0,1,5.85-5.85h138.73a5.879,5.879,0,0,1,5.43,3.68,6.48151,6.48151,0,0,1,.22.66,5.80007,5.80007,0,0,1,.2,1.51Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#3f3d56"
    />
    <path
      id="f7f5684e-e384-4873-9fc1-6b481c0c3185"
      data-name="Path 337"
      d="M718.48455,315.66827h-41.786a3.343,3.343,0,0,1,0-6.686h41.786a3.343,3.343,0,1,1,0,6.686Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#3f3d56"
    />
    <path
      id="a0d63c63-f814-470b-a777-b0a1c00c2ac9"
      data-name="Path 338"
      d="M718.48455,333.21828h-41.786a3.343,3.343,0,0,1,0-6.686h41.786a3.343,3.343,0,1,1,0,6.686Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#3f3d56"
    />
    <path
      id="ff5e0da0-ae1b-43c5-a91c-4cd72feaad08"
      data-name="Path 339"
      d="M655.25856,343.21127h-30.537a3.765,3.765,0,0,1-3.761-3.761v-36.036a3.765,3.765,0,0,1,3.761-3.761h30.537a3.765,3.765,0,0,1,3.761,3.761v36.036A3.765,3.765,0,0,1,655.25856,343.21127Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#3d00ff"
    />
    <path
      id="ad4ed544-ba1f-4890-b966-6cbda7ed2ca0"
      data-name="Path 340"
      d="M718.19457,369.15426h-94.436a3.343,3.343,0,0,1,0-6.686h94.436a3.343,3.343,0,1,1,0,6.686Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#e6e6e6"
    />
    <path
      id="bb4cdd45-0f85-4a7a-88d0-4f1a9d7bd917"
      data-name="Path 341"
      d="M718.19457,386.70425h-94.436a3.343,3.343,0,0,1-.01855-6.686h94.45456a3.343,3.343,0,0,1,0,6.686Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#e6e6e6"
    />
    <path
      id="a6089264-0f27-4995-b04f-a5be86198b98"
      data-name="Path 342"
      d="M718.19457,404.2543h-94.436a3.343,3.343,0,0,1,0-6.686h94.436a3.343,3.343,0,1,1,.01855,6.686Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#e6e6e6"
    />
    <path
      id="a4f00200-1086-48db-adf1-de08956240d5"
      data-name="Path 343"
      d="M718.19457,421.80428h-94.436a3.343,3.343,0,1,1-.01855-6.686h94.45456a3.343,3.343,0,0,1,0,6.686Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#e6e6e6"
    />
    <path
      id="b49f179d-0424-4766-955e-eb78e4d9d5b3"
      data-name="Path 344"
      d="M718.19457,439.35427h-94.436a3.343,3.343,0,1,1-.01855-6.686h94.45456a3.343,3.343,0,0,1,0,6.686Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#e6e6e6"
    />
    <circle
      id="ba183dc1-a597-4106-bea7-eb7523e98551"
      data-name="Ellipse 44"
      cx="383.55115"
      cy="67.62854"
      r="54"
      fill="#3d00ff"
    />
    <path
      id="a4d5b786-8a25-41ee-bd38-08ceac89f4c5"
      data-name="Path 692"
      d="M773.28057,263.10526v-29.791h-15.342v29.791h-7.163l7.416,12.845,7.418,12.847,7.418-12.847,7.417-12.847Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#fff"
    />
    <path
      id="fb232c29-52fe-45d7-b1d2-fa271477c305"
      data-name="Path 717"
      d="M784.8496,706.31427h-381a1,1,0,1,1,0-2h381a1,1,0,0,1,0,2Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#e6e6e6"
    />
    <path
      id="b543f4e5-af9f-4f22-96b3-c92f4b8b4cba"
      data-name="Path 694"
      d="M752.29859,697.42127c-9.6,6.915-22.039,7.262-34.063,6.413-1.679-.118-3.346-.257-5-.407h-.033c-.079-.008-.159-.015-.235-.02l-1.02-.1.08.152.25.472c-.09-.158-.18-.313-.27-.471-.026-.047-.056-.095-.082-.142-3.115-5.421-6.2-11.04-6.992-17.23-.815-6.425,1.475-13.7,7.129-16.857a11.40586,11.40586,0,0,1,2.341-.981q.507-.15,1.028-.26a12.23,12.23,0,0,1,14.223,15.9c4.794-4.916,3.9-12.89,2.246-19.555s-3.663-14.081-.293-20.067a13.23294,13.23294,0,0,1,8.865-6.232c.113-.024.227-.049.34-.07a17.8481,17.8481,0,0,1,12.823,2.247c7.069,4.455,10.7,12.9,11.878,21.174C767.41359,674.70026,763.21158,689.56025,752.29859,697.42127Z"
      transform="translate(-381.22442 -193.68573)"
      fill="#f2f2f2"
    />
  </svg>
);

const StepThree = () => (
  <svg
    id="ad94a318-fdfb-45a5-9490-4bb7b0f90139"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="300"
    height="200"
    viewBox="0 0 600 779.70903"
  >
    <title>begin chat</title>
    <path
      d="M581.64981,78.4088c-105.4864,0-191,56.81583-191,126.9017,0,31.29389,17.05887,59.9363,45.322,82.06248V379.4766l60.5461-60.54614a271.67266,271.67266,0,0,0,85.13187,13.28173c105.48639,0,191-56.81583,191-126.90169S687.1362,78.4088,581.64981,78.4088Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#f2f2f2"
    />
    <g opacity="0.3">
      <path
        d="M902.58471,710.05937a20.81252,20.81252,0,1,0,8.3387-39.13277l-.98562,10.67947-3.7314-9.80018a20.73405,20.73405,0,0,0-12.22123,10.10949,20.4084,20.4084,0,0,0-1.97438,5.40651A20.80854,20.80854,0,0,0,902.58471,710.05937Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#57b894"
      />
      <path
        d="M910.00074,836.5912c-.49451-9.27109,8.31332-16.18134,16.63109-20.306s17.89981-7.97772,21.73547-16.43263c5.51252-12.15118-3.91862-25.32906-11.121-36.56135a125.3002,125.3002,0,0,1-12.91856-26.63091c-1.29809-3.75147-2.42859-7.64108-2.34816-11.61.11584-5.71565,2.71274-11.052,5.29035-16.15471q12.88016-25.49811,26.95418-50.36938"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M903.81992,707.44879a20.81252,20.81252,0,1,0,8.33869-39.13278l-.98561,10.67948-3.7314-9.80018a20.734,20.734,0,0,0-12.22123,10.10949,20.40859,20.40859,0,0,0-1.97439,5.4065A20.80855,20.80855,0,0,0,903.81992,707.44879Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M933.58025,651.86777A20.78824,20.78824,0,0,1,950.26208,640.9l.26936,10.32343,4.64475-10.1448a20.81,20.81,0,1,1-21.59594,10.78916Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#57b894"
      />
      <path
        d="M935.2272,648.387A20.7882,20.7882,0,0,1,951.909,637.4192l.26936,10.32344,4.64475-10.14481A20.81,20.81,0,1,1,935.2272,648.387Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M933.6,762.98355a20.81,20.81,0,0,0,23.42627-34.07564l-3.56911,7.796-.2698-10.44473a.36282.36282,0,0,0-.04963-.02732A20.81113,20.81113,0,1,0,933.6,762.98355Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#57b894"
      />
      <path
        d="M935.1025,759.43443a20.81,20.81,0,0,0,23.42627-34.07565l-3.56912,7.796L954.68986,722.71a.36409.36409,0,0,0-.04964-.02732,20.81113,20.81113,0,1,0-19.53772,36.75173Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M907.30272,794.16821a20.80131,20.80131,0,1,0,6.25521-15.57438l7.225,15.11843-11.50663-9.11037A20.61639,20.61639,0,0,0,907.30272,794.16821Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#57b894"
      />
      <path
        d="M908.53792,791.55763a20.80131,20.80131,0,1,0,6.25521-15.57438l7.225,15.11842-11.50663-9.11037A20.61655,20.61655,0,0,0,908.53792,791.55763Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M352.359,713.05937a20.81252,20.81252,0,1,1-8.3387-39.13277l.98562,10.67947,3.7314-9.80018a20.73405,20.73405,0,0,1,12.22123,10.10949,20.40878,20.40878,0,0,1,1.97439,5.40651A20.80855,20.80855,0,0,1,352.359,713.05937Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M344.94294,839.5912c.49451-9.27109-8.31332-16.18134-16.63109-20.306s-17.89981-7.97772-21.73547-16.43263c-5.51252-12.15118,3.91862-25.32906,11.121-36.56135A125.3002,125.3002,0,0,0,330.616,739.66034c1.29809-3.75147,2.42859-7.64108,2.34816-11.61-.11584-5.71565-2.71274-11.052-5.29035-16.15471q-12.88016-25.49811-26.95418-50.36938"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M351.12376,710.44879a20.81252,20.81252,0,1,1-8.33869-39.13278l.98561,10.67948,3.7314-9.80018a20.734,20.734,0,0,1,12.22124,10.10949,20.40891,20.40891,0,0,1,1.97438,5.4065A20.80855,20.80855,0,0,1,351.12376,710.44879Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M321.36343,654.86777A20.78824,20.78824,0,0,0,304.6816,643.9l-.26935,10.32343-4.64476-10.1448a20.81,20.81,0,1,0,21.59594,10.78916Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M319.71649,651.387a20.78823,20.78823,0,0,0-16.68183-10.9678l-.26936,10.32344-4.64475-10.14481A20.81,20.81,0,1,0,319.71649,651.387Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M321.34367,765.98355a20.81,20.81,0,0,1-23.42627-34.07564l3.56911,7.796.2698-10.44473a.36282.36282,0,0,1,.04963-.02732,20.81113,20.81113,0,1,1,19.53773,36.75172Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M319.84118,762.43443a20.81,20.81,0,0,1-23.42627-34.07565l3.56912,7.796.26979-10.44474a.36409.36409,0,0,1,.04964-.02732,20.81113,20.81113,0,1,1,19.53772,36.75173Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M347.641,797.16821a20.80131,20.80131,0,1,1-6.25521-15.57438l-7.225,15.11843,11.50663-9.11037A20.61639,20.61639,0,0,1,347.641,797.16821Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M346.40576,794.55763a20.80131,20.80131,0,1,1-6.25521-15.57438l-7.225,15.11842,11.50663-9.11037A20.61655,20.61655,0,0,1,346.40576,794.55763Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M872.359,712.05937a20.81252,20.81252,0,1,1-8.3387-39.13277l.98562,10.67947,3.7314-9.80018a20.73405,20.73405,0,0,1,12.22123,10.10949,20.40878,20.40878,0,0,1,1.97439,5.40651A20.80855,20.80855,0,0,1,872.359,712.05937Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M864.94294,838.5912c.49451-9.27109-8.31332-16.18134-16.63109-20.306s-17.89981-7.97772-21.73547-16.43263c-5.51252-12.15118,3.91862-25.32906,11.121-36.56135A125.3002,125.3002,0,0,0,850.616,738.66034c1.29809-3.75147,2.42859-7.64108,2.34816-11.61-.11584-5.71565-2.71274-11.052-5.29035-16.15471q-12.88015-25.49811-26.95418-50.36938"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M871.12376,709.44879a20.81252,20.81252,0,1,1-8.33869-39.13278l.98561,10.67948,3.7314-9.80018a20.734,20.734,0,0,1,12.22124,10.10949,20.40891,20.40891,0,0,1,1.97438,5.4065A20.80855,20.80855,0,0,1,871.12376,709.44879Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M841.36343,653.86777A20.78824,20.78824,0,0,0,824.6816,642.9l-.26935,10.32343-4.64476-10.1448a20.81,20.81,0,1,0,21.59594,10.78916Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M839.71649,650.387a20.78823,20.78823,0,0,0-16.68183-10.9678l-.26936,10.32344-4.64475-10.14481A20.81,20.81,0,1,0,839.71649,650.387Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M841.34367,764.98355a20.81,20.81,0,0,1-23.42627-34.07564l3.56911,7.796.2698-10.44473a.36282.36282,0,0,1,.04963-.02732,20.81113,20.81113,0,1,1,19.53773,36.75172Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M839.84118,761.43443a20.81,20.81,0,0,1-23.42627-34.07565l3.56912,7.796.26979-10.44474a.36409.36409,0,0,1,.04964-.02732,20.81113,20.81113,0,1,1,19.53772,36.75173Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M867.641,796.16821a20.80131,20.80131,0,1,1-6.25521-15.57438l-7.225,15.11843,11.50663-9.11037A20.61639,20.61639,0,0,1,867.641,796.16821Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M866.40576,793.55763a20.80131,20.80131,0,1,1-6.25521-15.57438l-7.225,15.11842,11.50663-9.11037A20.61655,20.61655,0,0,1,866.40576,793.55763Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M132.359,711.05937a20.81252,20.81252,0,1,1-8.3387-39.13277l.98562,10.67947,3.7314-9.80018a20.73405,20.73405,0,0,1,12.22123,10.10949,20.40878,20.40878,0,0,1,1.97439,5.40651A20.80855,20.80855,0,0,1,132.359,711.05937Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M124.94294,837.5912c.49451-9.27109-8.31332-16.18134-16.63109-20.306S90.412,809.30751,86.57638,800.8526c-5.51252-12.15118,3.91862-25.32906,11.121-36.56135A125.3002,125.3002,0,0,0,110.616,737.66034c1.29809-3.75147,2.42859-7.64108,2.34816-11.61-.11584-5.71565-2.71274-11.052-5.29035-16.15471Q94.79363,684.39755,80.7196,659.52628"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M131.12376,708.44879a20.81252,20.81252,0,1,1-8.33869-39.13278l.98561,10.67948,3.7314-9.80018a20.734,20.734,0,0,1,12.22124,10.10949,20.40891,20.40891,0,0,1,1.97438,5.4065A20.80855,20.80855,0,0,1,131.12376,708.44879Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M101.36343,652.86777A20.78824,20.78824,0,0,0,84.6816,641.9l-.26935,10.32343-4.64476-10.1448a20.81,20.81,0,1,0,21.59594,10.78916Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M99.71649,649.387a20.78823,20.78823,0,0,0-16.68183-10.9678l-.26936,10.32344-4.64475-10.14481A20.81,20.81,0,1,0,99.71649,649.387Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M101.34367,763.98355A20.81,20.81,0,0,1,77.9174,729.90791l3.56911,7.796.2698-10.44473a.36282.36282,0,0,1,.04963-.02732,20.81113,20.81113,0,1,1,19.53773,36.75172Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M99.84118,760.43443a20.81,20.81,0,0,1-23.42627-34.07565l3.56912,7.796L80.25382,723.71a.36409.36409,0,0,1,.04964-.02732,20.81113,20.81113,0,1,1,19.53772,36.75173Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M127.641,795.16821a20.80131,20.80131,0,1,1-6.25521-15.57438l-7.225,15.11843,11.50663-9.11037A20.61639,20.61639,0,0,1,127.641,795.16821Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M126.40576,792.55763a20.80131,20.80131,0,1,1-6.25521-15.57438l-7.225,15.11842,11.50663-9.11037A20.61655,20.61655,0,0,1,126.40576,792.55763Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M126.76815,618.71937a35.75043,35.75043,0,1,0,14.32368-67.2198l-1.693,18.34453L132.98924,553.01a35.61557,35.61557,0,0,0-20.99286,17.36543,35.05685,35.05685,0,0,0-3.39147,9.287A35.74361,35.74361,0,0,0,126.76815,618.71937Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#57b894"
      />
      <path
        d="M139.50694,836.0677c-.84945-15.92529,14.28009-27.79528,28.56782-34.8803s30.74716-13.70362,37.33581-28.22692c9.46906-20.87254-6.73116-43.50865-19.103-62.80278a215.23227,215.23227,0,0,1-22.19068-45.74488c-2.22978-6.444-4.17168-13.12536-4.03352-19.94287.199-9.818,4.65977-18.98436,9.08742-27.74952q22.12471-43.799,46.30019-86.52133"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M128.8899,614.23507a35.75043,35.75043,0,1,0,14.32369-67.21979l-1.693,18.34452L135.111,548.52568a35.61559,35.61559,0,0,0-20.99286,17.36544,35.05651,35.05651,0,0,0-3.39147,9.287A35.74359,35.74359,0,0,0,128.8899,614.23507Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M180.01032,518.76154a35.70865,35.70865,0,0,1,28.655-18.83978l.46268,17.73294,7.97847-17.4261a35.746,35.746,0,1,1-37.09613,18.53294Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#57b894"
      />
      <path
        d="M182.83933,512.78248a35.70867,35.70867,0,0,1,28.655-18.83978l.46268,17.73294,7.97847-17.4261a35.746,35.746,0,1,1-37.09613,18.53294Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M180.04426,709.62916a35.74611,35.74611,0,0,0,40.24015-58.533l-6.13079,13.39143-.46344-17.9413a.62351.62351,0,0,0-.08526-.04693,35.748,35.748,0,1,0-33.56066,63.12977Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#57b894"
      />
      <path
        d="M182.62514,703.5327a35.74611,35.74611,0,0,0,40.24015-58.533l-6.1308,13.39142-.46344-17.94129a.62243.62243,0,0,0-.08526-.04693,35.748,35.748,0,1,0-33.56065,63.12977Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M134.87244,763.19619a35.73117,35.73117,0,1,0,10.7448-26.75268L158.0279,762.413l-19.76535-15.64921A35.41352,35.41352,0,0,0,134.87244,763.19619Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#57b894"
      />
      <path
        d="M136.9942,758.7119a35.73116,35.73116,0,1,0,10.7448-26.75269l12.41066,25.96947L140.3843,742.27947A35.41353,35.41353,0,0,0,136.9942,758.7119Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M982.69177,838.18123c-1.09964-20.61569,18.48592-35.98168,36.98174-45.1534s39.803-17.73968,48.33212-36.54044c12.25792-27.02-8.71366-56.323-24.72931-81.29973A278.6237,278.6237,0,0,1,1014.55,615.9698c-2.8865-8.34195-5.40034-16.9911-5.22149-25.81653.25758-12.7096,6.03219-24.57572,11.76389-35.92244q28.641-56.69892,59.93672-112.004"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M1105.10722,400.18977l-20.13538,5.75293,1.39819-9.32147c-1.7727-.11408-3.57177-.17853-5.39819-.17853-26.23352,0-47.5,12.08832-47.5,27s21.26648,27,47.5,27,47.5-12.08832,47.5-27C1128.47184,413.54091,1119.089,404.889,1105.10722,400.18977Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M1105.10722,397.18977l-20.13538,5.75293,1.39819-9.32147c-1.7727-.11408-3.57177-.17853-5.39819-.17853-26.23352,0-47.5,12.08832-47.5,27s21.26648,27,47.5,27,47.5-12.08832,47.5-27C1128.47184,410.54091,1119.089,401.889,1105.10722,397.18977Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M1046.10722,743.18977l-20.13538,5.75293,1.39819-9.32147c-1.7727-.11408-3.57177-.17853-5.39819-.17853-26.23352,0-47.5,12.08832-47.5,27s21.26648,27,47.5,27,47.5-12.08832,47.5-27C1069.47184,756.54091,1060.089,747.889,1046.10722,743.18977Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M1046.10722,740.18977l-20.13538,5.75293,1.39819-9.32147c-1.7727-.11408-3.57177-.17853-5.39819-.17853-26.23352,0-47.5,12.08832-47.5,27s21.26648,27,47.5,27,47.5-12.08832,47.5-27C1069.47184,753.54091,1060.089,744.889,1046.10722,740.18977Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M1116.10722,671.18977l-20.13538,5.75293,1.39819-9.32147c-1.7727-.11408-3.57177-.17853-5.39819-.17853-26.23352,0-47.5,12.08832-47.5,27s21.26648,27,47.5,27,47.5-12.08832,47.5-27C1139.47184,684.54091,1130.089,675.889,1116.10722,671.18977Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M1116.10722,668.18977l-20.13538,5.75293,1.39819-9.32147c-1.7727-.11408-3.57177-.17853-5.39819-.17853-26.23352,0-47.5,12.08832-47.5,27s21.26648,27,47.5,27,47.5-12.08832,47.5-27C1139.47184,681.54091,1130.089,672.889,1116.10722,668.18977Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M1033.33668,487.46017l-20.55989-3.97753,5.46353-7.68079c-1.52937-.90362-3.1047-1.77491-4.73357-2.60109-23.39613-11.86672-47.83058-10.70574-54.57587,2.59311s6.75288,33.69958,30.149,45.56629,47.83059,10.70574,54.57588-2.5931C1048.13483,509.93623,1043.68054,497.97579,1033.33668,487.46017Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M1034.69373,484.78465l-20.55989-3.97753,5.46353-7.6808c-1.52937-.90361-3.1047-1.77491-4.73357-2.60108-23.39613-11.86672-47.83059-10.70574-54.57587,2.5931s6.75288,33.69958,30.149,45.5663,47.83058,10.70574,54.57587-2.59311C1049.49187,507.26071,1045.03759,495.30027,1034.69373,484.78465Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M1078.33668,592.46017l-20.55989-3.97753,5.46353-7.68079c-1.52937-.90362-3.1047-1.77491-4.73357-2.60109-23.39613-11.86672-47.83058-10.70574-54.57587,2.59311s6.75288,33.69958,30.149,45.56629,47.83059,10.70574,54.57588-2.5931C1093.13483,614.93623,1088.68054,602.97579,1078.33668,592.46017Z"
        transform="translate(-60.02816 -59.9088)"
        fill="#ff6584"
      />
      <path
        d="M1079.69373,589.78465l-20.55989-3.97753,5.46353-7.6808c-1.52937-.90361-3.1047-1.77491-4.73357-2.60108-23.39613-11.86672-47.83059-10.70574-54.57587,2.5931s6.75288,33.69958,30.149,45.5663,47.83058,10.70574,54.57587-2.59311C1094.49187,612.26071,1090.03759,600.30027,1079.69373,589.78465Z"
        transform="translate(-60.02816 -59.9088)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
    </g>
    <line
      x1="8.94368"
      y1="778.0339"
      x2="1060.94368"
      y2="778.0339"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M595.14981,60.4088c-105.4864,0-191,56.81583-191,126.9017,0,31.29389,17.05887,59.9363,45.322,82.06248V361.4766l60.5461-60.54614a271.67266,271.67266,0,0,0,85.13187,13.28173c105.48639,0,191-56.81583,191-126.90169S700.6362,60.4088,595.14981,60.4088Z"
      transform="translate(-60.02816 -59.9088)"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M512.15993,388.77323s-3.84207-51.7406-2.03317-61.2037,3.508-36.40025-6.43762-27.54543-6.41234,28.945-6.41234,28.945-9.43783,54.68156-.7088,62.10017S512.15993,388.77323,512.15993,388.77323Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#a0616a"
    />
    <path
      d="M519.32289,439.94013s-29.60358-26.4032-28.00339-48.80591c0,0,17.60213-13.60164,21.60261-6.40077s33.07874,35.293,28.14077,44.84978S519.32289,439.94013,519.32289,439.94013Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#3d00ff"
    />
    <path
      d="M624.13557,392.73442s17.60213-48.80591,18.40223-58.40707,6.40078-36.00436,13.60165-24.803-1.6002,29.60359-1.6002,29.60359-5.60067,55.20668-16.00193,60.00726S624.13557,392.73442,624.13557,392.73442Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#a0616a"
    />
    <path
      d="M576.12976,637.564h47.20572l2.40029,24.0029-11.20136,2.40029s-26.40319-8.001-41.605-5.60068S576.12976,637.564,576.12976,637.564Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#a0616a"
    />
    <path
      d="M607.33354,634.36366s22.40271-15.20184,26.4032-2.40029,18.40222,59.20716,13.60164,64.00774-10.40126-4.00048-10.40126-4.00048-9.60116-20.00242-16.802-20.80252-11.20136-7.20087-11.20136-7.20087S629.73625,647.9653,607.33354,634.36366Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#2f2e41"
    />
    <path
      d="M564.12831,758.37867s8.80107,36.00435,7.20087,41.605,25.6031,2.40029,25.6031,2.40029l1.6002-10.40126s-16.00194-25.60309-16.00194-36.80445S564.12831,758.37867,564.12831,758.37867Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#a0616a"
    />
    <path
      d="M596.93228,784.78186s21.60262,16.00194,10.40126,24.00291-52.80639,28.80348-52.80639,28.80348-15.20184,2.40029-4.00048-11.20135,14.40174-16.00194,15.20184-23.20281a35.12235,35.12235,0,0,1,4.93378-13.50807S581.73044,811.98516,596.93228,784.78186Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#2f2e41"
    />
    <path
      d="M531.32434,592.75862s-8.80106,83.21007,4.80058,109.61327,29.60359,62.40755,28.80349,64.00775,23.20281-2.40029,23.20281-9.60117-26.4032-79.20958-26.4032-79.20958,11.20136-93.61133,20.00242-100.01211S531.32434,592.75862,531.32434,592.75862Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#2f2e41"
    />
    <path
      d="M531.32434,592.75862s-8.80106,83.21007,4.80058,109.61327,29.60359,62.40755,28.80349,64.00775,23.20281-2.40029,23.20281-9.60117-26.4032-79.20958-26.4032-79.20958,11.20136-93.61133,20.00242-100.01211S531.32434,592.75862,531.32434,592.75862Z"
      transform="translate(-60.02816 -59.9088)"
      opacity="0.1"
    />
    <path
      d="M549.408,357.9302c-5.71909,1.03547-11.38512,4.17742-14.22925,9.54354-2.653,5.00556-2.82174,11.69636-7.04249,15.29089-2.51961,2.14579-5.94042,2.642-8.75359,4.32764a13.48165,13.48165,0,0,0-6.20118,12.00756c.22526,4.41433,2.55341,8.52613,2.504,12.94662-.06423,5.747-4.03814,10.492-7.77574,14.65147l12.39544,2.30022a12.33926,12.33926,0,0,0,5.73441.15205c2.141-.63381,3.88-2.3967,6.04427-2.93423,3.10353-.7708,6.17451,1.117,8.94366,2.792,7.88245,4.76774,16.33437,8.36624,24.7557,11.94919,2.4775,1.05409,5.2865,2.10927,7.73121.97222,1.97439-.91831,3.20232-3.06489,5.05944-4.22647,3.32564-2.08013,7.50159-.51438,11.23869.51252a37.15163,37.15163,0,0,0,25.38312-2.16146c-1.71207-1.99985-3.956-3.39847-5.80095-5.26048s-3.33756-4.49874-2.85015-7.15065c.49706-2.70439,2.82217-4.50972,4.40694-6.69233a15.724,15.724,0,0,0,2.39236-11.92405,21.75365,21.75365,0,0,0-5.70746-10.80127,57.05128,57.05128,0,0,1-4.66873-4.923c-2.9161-3.93793-3.638-9.15131-4.57086-14.06082s-2.40574-10.13442-6.19237-13.12385c-6.0326-4.76258-15.829-3.13919-22.88141-3.61191C562.59921,358.05486,556.11633,357.9302,549.408,357.9302Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#2f2e41"
    />
    <path
      d="M509.72173,535.95175l15.20184,37.60455s-69.60843,76.0092-30.40368,86.41046,52.80639-8.001,77.60939,6.40077c0,0,12.00145-18.40223,10.40126-29.60358,0,0-34.40416-6.40078-52.80639-5.60068,0,0,49.606-43.20523,54.40658-44.80542s20.80252-27.20329-4.00048-48.00581S561.728,506.34817,561.728,506.34817Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#2f2e41"
    />
    <path
      d="M545.72608,405.536s2.4003,24.803-8.001,28.00339,40.80493,2.40029,40.80493,2.40029-12.00145-7.20087-6.40077-22.40271S545.72608,405.536,545.72608,405.536Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#a0616a"
    />
    <path
      d="M545.72608,405.536s2.4003,24.803-8.001,28.00339,40.80493,2.40029,40.80493,2.40029-12.00145-7.20087-6.40077-22.40271S545.72608,405.536,545.72608,405.536Z"
      transform="translate(-60.02816 -59.9088)"
      opacity="0.1"
    />
    <path
      d="M600.13267,448.74119s-4.80058-24.803-14.40174-23.2028-28.80349,3.20038-31.20378,4.80058-15.20184,4.87942-15.20184,4.87942,4.80058-5.67952,0-6.47962-20.00242,3.20039-21.60261,6.40078-8.001,9.60116-8.001,20.80251-4.80058,30.40368-3.20039,38.40465-.8001,26.4032-5.60068,36.80446-.80009,20.80251,7.20087,10.40126,4.00049,0,10.40126.80009S553.72705,513.549,561.728,520.74991s16.00194,12.00145,17.60213,8.001-6.40077-11.20136-6.40077-14.40175-12.80155,10.40126.80009-17.60213S574.52957,460.74265,600.13267,448.74119Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#3d00ff"
    />
    <path
      d="M593.73189,447.141l6.40078,1.60019s23.20281-16.802,29.60358-28.80348,11.20136-21.60262,11.20136-21.60262-10.40126-12.80155-16.802-8.001-35.20426,37.60456-35.20426,37.60456Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#3d00ff"
    />
    <circle cx="504.10015" cy="337.6262" r="24.803" fill="#a0616a" />
    <path
      d="M542.18677,390.34991a21.7837,21.7837,0,0,1,15.14908-4.98222c9.50754.58506,17.87685,7.35994,27.4,7.57163a9.81952,9.81952,0,0,1,.81893-6.07049c.7385-1.51341,1.904-2.97985,1.74722-4.6565a5.67954,5.67954,0,0,0-2.05426-3.36417c-5.50468-5.21967-13.11558-7.90984-20.69427-8.24123-5.08113-.22219-14.77917-.10179-18.71994,3.74566C542.462,377.6443,542.854,386.083,542.18677,390.34991Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#2f2e41"
    />
    <circle cx="535.12165" cy="151.0339" r="52" fill="#3d00ff" />
    <ellipse
      cx="522.41588"
      cy="131.4409"
      rx="4.98731"
      ry="6.531"
      fill="#444053"
    />
    <ellipse
      cx="548.18364"
      cy="131.4409"
      rx="4.98731"
      ry="6.531"
      fill="#444053"
    />
    <path
      d="M626.32048,211.89266c0,9.37813-15.66245,25.174-31.46754,25.174s-30.87381-16.15215-30.87381-25.53027,14.74217-.80153,30.54726-.80153S626.32048,202.51454,626.32048,211.89266Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#3f3d56"
    />
    <path
      d="M568.96,213.49573s28.40984,6.32319,52.27768.17811c0,0,2.92086,0,.92546,3.377a5.37878,5.37878,0,0,1-2.18872,2.04678c-4.60034,2.35056-21.62977,9.29771-48.86055.43734a5.35606,5.35606,0,0,1-3.53441-3.62139C567.25876,214.75413,567.397,213.656,568.96,213.49573Z"
      transform="translate(-60.02816 -59.9088)"
      fill="#f6f8fb"
    />
  </svg>
);

const cards = [
  {
    id: 1,
    component: StepOne,
    title: 'Customize your order or product attributes to export',
    description:
      'Choose our existing (with more on the way) fields that you need to export.',
  },
  {
    id: 2,
    component: StepTwo,
    title: 'Decide on file type and destination',
    description:
      'As of now, we help export CSV and Excel files via Email, Google Sheets and to FTP servers.',
  },
  {
    id: 3,
    component: StepThree,
    title: 'Support via email',
    description:
      'Export Stash is growing and we promise to do our best in assisting you. Simple email us at tech@tamagotech.com should you have need of help or feature requests.',
  },
];
