import React from 'react';
import ReactDOM from 'react-dom';

import App from '@/pages/App';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

const config = {
  apiKey: 'de291d02',
  environment: process.env.NODE_ENV,
};
const honeybadger = Honeybadger.configure(config);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <App />
    </HoneybadgerErrorBoundary>,
    document.body.appendChild(document.createElement('div'))
  );
});
