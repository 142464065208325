import { gql } from '@apollo/client';

export const getAllProfiles = gql`
  query {
    allProductMetafieldsProfiles {
      slug
      profileType
      status
      lastRun
      cronExpression
      profileName
      exportMethod
      fileExtension
      columnSeparator
      ftpHost
      ftpUsername
      decryptedFtpPassword
      ftpFilePath
      googleSheetUrl
      googleSheetName
      recipientEmail
      emailSubject
      emailBody
      fileName
      allFields
      scheduleEnabled
      scheduleType
      scheduleFrequency
      scheduleRunTime
      hourlyFrequency
      scheduleCustomDays
      exportOnNewPage
      includeVariantMetafields
      templateName
      connectionParams
      exportFields {
        fieldName
        identifier
        position
        staticValue
      }
      latestLog {
        success
        remark
        startedBy
        totalExportedData
      }
      webhook {
        scope
      }
      googleClearSheet
      googleAppendNextRow
      emailSender
    }
  }
`;

export const deleteProfile = gql`
  mutation ($slug: String!) {
    deleteProfile(slug: $slug) {
      success
      message
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        includeVariantMetafields
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const editProfile = gql`
  mutation ($slug: String!, $attributes: ProductMetafieldsProfileInput!) {
    editProductMetafieldsProfile(slug: $slug, attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        includeVariantMetafields
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const createNewProductMetafieldsProfile = gql`
  mutation ($attributes: ProductMetafieldsProfileInput!) {
    createProductMetafieldsProfile(attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        includeVariantMetafields
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const duplicateProfile = gql`
  mutation ($slug: String!) {
    duplicateProductMetafieldsProfile(slug: $slug) {
      success
      message
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        includeVariantMetafields
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const editSchedule = gql`
  mutation ($slug: String!, $schedule: ScheduleInput!) {
    setProductMetafieldsSchedule(slug: $slug, schedule: $schedule) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        includeVariantMetafields
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;
