import { gql } from '@apollo/client';

export const getAllProfiles = gql`
  query {
    allProductReviewsProfiles {
      slug
      profileType
      status
      lastRun
      cronExpression
      profileName
      exportMethod
      fileExtension
      columnSeparator
      ftpHost
      ftpUsername
      decryptedFtpPassword
      ftpFilePath
      googleSheetUrl
      googleSheetName
      recipientEmail
      emailSubject
      emailBody
      fileName
      allFields
      scheduleEnabled
      scheduleType
      scheduleFrequency
      scheduleRunTime
      hourlyFrequency
      scheduleCustomDays
      exportOnNewPage
      templateName
      connectionParams
      exportFields {
        fieldName
        identifier
        position
        staticValue
      }
      latestLog {
        success
        remark
        startedBy
        totalExportedData
      }
      webhook {
        scope
      }
      googleClearSheet
      googleAppendNextRow
      emailSender
    }
  }
`;

export const deleteProfile = gql`
  mutation ($slug: String!) {
    deleteProfile(slug: $slug) {
      success
      message
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const editProfile = gql`
  mutation ($slug: String!, $attributes: ProductReviewsProfileInput!) {
    editProductReviewsProfile(slug: $slug, attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const createNewProductReviewsProfile = gql`
  mutation ($attributes: ProductReviewsProfileInput!) {
    createProductReviewsProfile(attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const duplicateProfile = gql`
  mutation ($slug: String!) {
    duplicateProductReviewsProfile(slug: $slug) {
      success
      message
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const editSchedule = gql`
  mutation ($slug: String!, $schedule: ScheduleInput!) {
    setProductReviewsSchedule(slug: $slug, schedule: $schedule) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const createNewTemplate = gql`
  mutation ($attributes: ProductReviewTemplateInput!) {
    createProductReviewTemplate(attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        templateName
        connectionParams
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;
