import React from 'react';
import { StoreContext } from '@/contexts/StoreContext';

export const useStore = () => {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a StoreProvider');
  }
  return context.currentStore;
};
