import { gql } from '@apollo/client';

export const getAllProfiles = gql`
  query {
    allOrderProfiles {
      slug
      profileType
      status
      lastRun
      cronExpression
      profileName
      exportMethod
      fileExtension
      columnSeparator
      ftpHost
      ftpUsername
      decryptedFtpPassword
      ftpFilePath
      googleSheetUrl
      googleSheetName
      recipientEmail
      emailSubject
      emailBody
      fileName
      allFields
      scheduleEnabled
      scheduleType
      scheduleFrequency
      scheduleRunTime
      hourlyFrequency
      scheduleCustomDays
      exportOnNewPage
      orderStatusFilter
      fulfillmentStatusFilter
      orderDateRangeFilter
      orderDaysFilter
      orderStatusUpdate
      partialStatusUpdate
      connectionParams
      orderEntireMonth
      orderPreviousMonth
      orderEntireYear
      templateName
      rowType
      customFilters {
        includeSkus
        excludeSkus
        includeProductIds
        excludeProductIds
        customerMessage
        orderDateCreated
        orderShippedDate
        includeProductCategories
        excludeProductCategories
        includeProductBrands
        excludeProductBrands
        includeProductCustomFields
        includeBin
        excludeBin
        includeOptionValues
        lineItemsCount
        orderState
        orderChannelId
        totalOrderValue
      }
      exportFields {
        fieldName
        identifier
        position
        staticValue
      }
      latestLog {
        success
        remark
        startedBy
        totalExportedData
      }
      webhook {
        scope
      }
      productCustomFields
      dateFormat
      googleClearSheet
      googleAppendNextRow
      emailSender
    }
  }
`;

export const deleteProfile = gql`
  mutation ($slug: String!) {
    deleteProfile(slug: $slug) {
      success
      message
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        orderStatusFilter
        fulfillmentStatusFilter
        orderDateRangeFilter
        orderDaysFilter
        orderStatusUpdate
        partialStatusUpdate
        connectionParams
        orderEntireMonth
        orderPreviousMonth
        orderEntireYear
        templateName
        rowType
        customFilters {
          includeSkus
          excludeSkus
          includeProductIds
          excludeProductIds
          customerMessage
          orderDateCreated
          orderShippedDate
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeProductCustomFields
          includeBin
          excludeBin
          includeOptionValues
          lineItemsCount
          orderState
          orderChannelId
          totalOrderValue
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        dateFormat
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const editProfile = gql`
  mutation ($slug: String!, $attributes: OrderProfileInput!) {
    editOrderProfile(slug: $slug, attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        exportOnNewPage
        orderStatusFilter
        fulfillmentStatusFilter
        orderDateRangeFilter
        orderDaysFilter
        orderStatusUpdate
        partialStatusUpdate
        connectionParams
        orderEntireMonth
        orderPreviousMonth
        orderEntireYear
        templateName
        rowType
        allFields

        customFilters {
          includeSkus
          excludeSkus
          includeProductIds
          excludeProductIds
          customerMessage
          orderDateCreated
          orderShippedDate
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeProductCustomFields
          includeBin
          excludeBin
          includeOptionValues
          lineItemsCount
          orderState
          orderChannelId
          totalOrderValue
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        dateFormat
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const createNewOrderProfile = gql`
  mutation ($attributes: OrderProfileInput!) {
    createOrderProfile(attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        orderStatusFilter
        fulfillmentStatusFilter
        orderDateRangeFilter
        orderDaysFilter
        orderStatusUpdate
        partialStatusUpdate
        connectionParams
        orderEntireMonth
        orderPreviousMonth
        orderEntireYear
        templateName
        rowType
        customFilters {
          includeSkus
          excludeSkus
          includeProductIds
          excludeProductIds
          customerMessage
          orderDateCreated
          orderShippedDate
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeProductCustomFields
          includeBin
          excludeBin
          includeOptionValues
          lineItemsCount
          orderState
          orderChannelId
          totalOrderValue
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        dateFormat
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const updateOrderFilters = gql`
  mutation ($slug: String!, $filters: OrderFilterInput!) {
    updateOrderFilter(slug: $slug, filters: $filters) {
      success
      message
      profile {
        slug
      }
    }
  }
`;

export const duplicateProfile = gql`
  mutation ($slug: String!) {
    duplicateOrderProfile(slug: $slug) {
      success
      message
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        orderStatusFilter
        fulfillmentStatusFilter
        orderDateRangeFilter
        orderDaysFilter
        orderStatusUpdate
        partialStatusUpdate
        connectionParams
        orderEntireMonth
        orderPreviousMonth
        orderEntireYear
        templateName
        rowType
        customFilters {
          includeSkus
          excludeSkus
          includeProductIds
          excludeProductIds
          customerMessage
          orderDateCreated
          orderShippedDate
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeProductCustomFields
          includeBin
          excludeBin
          includeOptionValues
          lineItemsCount
          orderState
          orderChannelId
          totalOrderValue
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        dateFormat
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const createNewTemplate = gql`
  mutation ($attributes: OrderTemplateInput!) {
    createOrderTemplate(attributes: $attributes) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        orderStatusFilter
        fulfillmentStatusFilter
        orderDateRangeFilter
        orderDaysFilter
        orderStatusUpdate
        partialStatusUpdate
        connectionParams
        orderEntireMonth
        orderPreviousMonth
        orderEntireYear
        templateName
        rowType
        customFilters {
          includeSkus
          excludeSkus
          includeProductIds
          excludeProductIds
          customerMessage
          orderDateCreated
          orderShippedDate
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeProductCustomFields
          includeBin
          excludeBin
          includeOptionValues
          lineItemsCount
          orderState
          orderChannelId
          totalOrderValue
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        dateFormat
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;

export const editSchedule = gql`
  mutation ($slug: String!, $schedule: ScheduleInput!) {
    setOrderSchedule(slug: $slug, schedule: $schedule) {
      message
      success
      profile {
        slug
        profileType
        status
        lastRun
        cronExpression
        profileName
        exportMethod
        fileExtension
        columnSeparator
        ftpHost
        ftpUsername
        decryptedFtpPassword
        ftpFilePath
        googleSheetUrl
        googleSheetName
        recipientEmail
        emailSubject
        emailBody
        fileName
        allFields
        scheduleEnabled
        scheduleType
        scheduleFrequency
        scheduleRunTime
        hourlyFrequency
        scheduleCustomDays
        exportOnNewPage
        orderStatusFilter
        fulfillmentStatusFilter
        orderDateRangeFilter
        orderDaysFilter
        orderStatusUpdate
        partialStatusUpdate
        connectionParams
        orderEntireMonth
        orderPreviousMonth
        orderEntireYear
        templateName
        rowType
        customFilters {
          includeSkus
          excludeSkus
          includeProductIds
          excludeProductIds
          customerMessage
          orderDateCreated
          orderShippedDate
          includeProductCategories
          excludeProductCategories
          includeProductBrands
          excludeProductBrands
          includeProductCustomFields
          includeBin
          excludeBin
          includeOptionValues
          lineItemsCount
          orderState
          orderChannelId
          totalOrderValue
        }
        exportFields {
          fieldName
          identifier
          position
          staticValue
        }
        latestLog {
          success
          remark
          startedBy
          totalExportedData
        }
        webhook {
          scope
        }
        productCustomFields
        dateFormat
        googleClearSheet
        googleAppendNextRow
        emailSender
      }
    }
  }
`;
