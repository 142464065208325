import React, { createContext, useState, useEffect } from 'react';
import Sockette from 'sockette';
import { IdleSessionTimeout } from 'idle-session-timeout';

import { useStore } from '@/hooks/useStore';

const PING_INTERVAL = 9 * 60 * 1000; // 9 minutes
const BROWSER_SESSION_DURATION = 5 * 60 * 60 * 1000; // 5 hours

const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {
  const { publicToken } = useStore();
  const [latestMessage, setLatestMessage] = useState({});
  let webSocket, timer;

  useEffect(() => {
    if (!publicToken) return;
    webSocket = new Sockette(webSocketUrlFor(publicToken), {
      timeout: 5e3,
      maxAttempts: 3,
      onopen: (e) => {
        console.debug('Connected...', e);
        webSocket.ws = e.target;
      },
      onmessage: ({ data }) => {
        setLatestMessage(JSON.parse(data));
      },
      onreconnect: (e) => {
        console.debug('Reconnecting...');
      },
      onmaximum: (e) => {
        console.debug('Max tries reached...', e);
        webSocket.ws = e.target;
      },
      onclose: (e) => {
        console.debug('Closing...', e.code);
        webSocket.ws = e.target;
      },
      onerror: (e) => {
        console.debug('Error:', e);
        webSocket.ws = e.target;
      },
    });

    const updateOnlineStatus = () => {
      if (navigator.onLine) {
        webSocket.open();
        // onStatusChange(true);
      } else {
        webSocket.close();
        // onStatusChange(false);
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // ping every 9 minutes to keep alive
    const keepAlive = () => {
      if (webSocket.ws) {
        if (webSocket.ws.readyState === 1) {
          webSocket.json({ action: 'ping' });
        } else {
          clearInterval(timer);
        }
      }
    };
    timer = setInterval(keepAlive, PING_INTERVAL);

    // detect inactive and close connection
    const browserSession = new IdleSessionTimeout(BROWSER_SESSION_DURATION);
    browserSession.onTimeOut = () => {
      console.debug('Browser session inactive, disconnecting websocket...');
      webSocket.close();
      //   onBrowserTimeout();
    };
    browserSession.start();

    return () => {
      webSocket.close();
      clearInterval(timer);
    };
  }, [publicToken]);

  return (
    <WebSocketContext.Provider value={{ latestMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

const WEBSOCKET_URL =
  process.env.NODE_ENV == 'production'
    ? 'wss://b31try7nhf.execute-api.us-west-2.amazonaws.com/production'
    : 'wss://b31try7nhf.execute-api.us-west-2.amazonaws.com/production';

const webSocketUrlFor = (token) => {
  return `${WEBSOCKET_URL}?publicToken=${token}`;
};

// TODO: expose useHook instead of useContext(WebSocketContext)
// https://kentcdodds.com/blog/how-to-use-react-context-effectively#the-custom-consumer-hook
export { WebSocketProvider, WebSocketContext };
