import {
  getAllProfiles,
  editSchedule as editProfileSchedule,
} from '@/queries/profile';
import {
  getAllProfiles as getAllOrderProfiles,
  editSchedule as editOrderSchedule,
} from '@/queries/order';
import {
  getAllProfiles as getAllCustomerProfiles,
  editSchedule as editCustomerSchedule,
} from '@/queries/customer';
import {
  getAllProfiles as getAllProductMetafieldsProfiles,
  editSchedule as editMetafieldsSchedule,
} from '@/queries/productMetafields';
import {
  getAllProfiles as getAllProductReviewsProfiles,
  editSchedule as editReviewsSchedule,
} from '@/queries/productReviews';
import {
  getAllProfiles as getAllGiftCertsProfiles,
  editSchedule as editGiftCertsSchedule,
} from '@/queries/giftCert';
import {
  getAllProfiles as getAllReportProfiles,
  editSchedule as editReportSchedule,
} from '@/queries/report';
import {
  getAllProfiles as getAllDataFeedProfiles,
  editSchedule as editDataFeedSchedule,
} from '@/queries/dataFeed';

import { updateProductFilters } from '@/queries/profile';

export const getFilterQuery = (profileType) => {
  return {
    product: {
      mutationName: updateProductFilters,
      queryName: getAllProfiles,
      key: 'allProfiles',
    },
    data_feed: {
      mutationName: updateProductFilters,
      queryName: getAllDataFeedProfiles,
      key: 'allDataFeedProfiles',
    },
    report: {
      mutationName: updateProductFilters,
      queryName: getAllReportProfiles,
      key: 'allReportProfiles',
    },
  }[profileType];
};

export const getMainQuery = (profileType) => {
  return {
    product: { query: getAllProfiles, key: 'allProfiles' },
    order: { query: getAllOrderProfiles, key: 'allOrderProfiles' },
    customer: { query: getAllCustomerProfiles, key: 'allCustomerProfiles' },
    product_metafields: {
      query: getAllProductMetafieldsProfiles,
      key: 'allProductMetafieldsProfiles',
    },
    product_reviews: {
      query: getAllProductReviewsProfiles,
      key: 'allProductReviewsProfiles',
    },
    gift_certs: {
      query: getAllGiftCertsProfiles,
      key: 'allGiftCertsProfiles',
    },
    report: {
      query: getAllReportProfiles,
      key: 'allReportProfiles',
    },
    data_feed: {
      query: getAllDataFeedProfiles,
      key: 'allDataFeedProfiles',
    },
  }[profileType];
};

export const getEditScheduleQuery = (profileType) => {
  return {
    product: { query: editProfileSchedule, key: 'setSchedule' },
    order: { query: editOrderSchedule, key: 'setOrderSchedule' },
    customer: { query: editCustomerSchedule, key: 'setCustomerSchedule' },
    product_metafields: {
      query: editMetafieldsSchedule,
      key: 'setProductMetafieldsSchedule',
    },
    product_reviews: {
      query: editReviewsSchedule,
      key: 'setProductReviewsSchedule',
    },
    gift_certs: {
      query: editGiftCertsSchedule,
      key: 'setGiftCertsSchedule',
    },
    report: {
      query: editReportSchedule,
      key: 'setReportSchedule',
    },
    data_feed: {
      query: editDataFeedSchedule,
      key: 'setDataFeedSchedule',
    },
  }[profileType];
};

export const renderCurrentProfileValue = (props) => {
  const { allFields } = props;
  const currentProfileValue = { fields: allFields, ...props };
  currentProfileValue.ftpPassword = currentProfileValue.decryptedFtpPassword;

  // delete currentProfileValue.filterByVisibility;
  delete currentProfileValue.scheduleEnabled;
  delete currentProfileValue.scheduleFrequency;
  delete currentProfileValue.scheduleRunTime;
  delete currentProfileValue.scheduleType;
  delete currentProfileValue.hourlyFrequency;
  delete currentProfileValue.scheduleCustomDays;
  delete currentProfileValue.webhook;
  delete currentProfileValue.allFields;
  delete currentProfileValue.exportFields;
  delete currentProfileValue.schedule;
  delete currentProfileValue.decryptedFtpPassword;
  delete currentProfileValue.slug;
  delete currentProfileValue.customFilters;
  // delete currentProfileValue.templateName;
  delete currentProfileValue.profileName;
  delete currentProfileValue.profileType;
  delete currentProfileValue.latestLog;
  delete currentProfileValue.__typename;
  return currentProfileValue;
};

export const renderTemplateValue = (props) => {
  const { allFields } = props;
  const currentProfileValue = { fields: allFields, ...props };
  currentProfileValue.ftpPassword = currentProfileValue.decryptedFtpPassword;

  // delete currentProfileValue.filterByVisibility;
  delete currentProfileValue.scheduleEnabled;
  delete currentProfileValue.scheduleFrequency;
  delete currentProfileValue.scheduleType;
  delete currentProfileValue.hourlyFrequency;
  delete currentProfileValue.scheduleRunTime;
  delete currentProfileValue.scheduleCustomDays;
  delete currentProfileValue.webhook;
  delete currentProfileValue.allFields;
  delete currentProfileValue.exportFields;
  delete currentProfileValue.schedule;
  delete currentProfileValue.decryptedFtpPassword;
  delete currentProfileValue.slug;
  delete currentProfileValue.customFilters;
  delete currentProfileValue.profileName;
  delete currentProfileValue.profileType;
  delete currentProfileValue.latestLog;
  delete currentProfileValue.__typename;
  return currentProfileValue;
};

export const renderReportValue = (props) => {
  const { allFields } = props;
  const currentProfileValue = { ...props };
  currentProfileValue.ftpPassword = currentProfileValue.decryptedFtpPassword;

  // delete currentProfileValue.filterByVisibility;
  delete currentProfileValue.connectionParams;
  delete currentProfileValue.scheduleEnabled;
  delete currentProfileValue.scheduleFrequency;
  delete currentProfileValue.scheduleType;
  delete currentProfileValue.hourlyFrequency;
  delete currentProfileValue.scheduleRunTime;
  delete currentProfileValue.scheduleCustomDays;
  delete currentProfileValue.webhook;
  delete currentProfileValue.allFields;
  delete currentProfileValue.exportFields;
  delete currentProfileValue.schedule;
  delete currentProfileValue.decryptedFtpPassword;
  delete currentProfileValue.slug;
  delete currentProfileValue.customFilters;
  delete currentProfileValue.profileName;
  delete currentProfileValue.profileType;
  delete currentProfileValue.latestLog;
  delete currentProfileValue.__typename;
  return currentProfileValue;
};

export const getDifferenceInDays = (endDate, startDate = null) => {
  const end = new Date(endDate);
  const start = startDate ? new Date(startDate) : new Date();

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = end.getTime() - start.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.abs(Math.round(diffInTime / oneDay));

  return diffInDays;
};
